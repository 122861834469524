import type { CustomFlowbiteTheme } from 'flowbite-react';

const flowbiteTheme: CustomFlowbiteTheme = {
    navbar: {
        link: {
            base: 'relative inline-flex items-center justify-left rounded-md p-2 focus:outline-none ' +
                // Begin portfolio customizations
                'text-white focus:ring-2 focus:ring-inset focus:ring-white hover:text-blue-200',
            active: {
                on: '',
                off: '',
            },
        },
        toggle: {
            base: 'relative inline-flex items-center rounded-lg p-2 text-sm focus:outline-none ' +
                'focus:ring-2 focus:ring-inset focus:ring-white md:hidden ' +
                // Begin portfolio customizations
                'focus:ring-indigo-500 bg-logoblue-100 text-white hover:bg-gray-700',
            icon: 'h-6 w-6 shrink-0',
        },
        collapse: {
            base: 'w-full md:block md:w-auto' +
                // Begin portfolio customizations
                '',
            list: 'mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium block rounded-md px-3 py-2 text-base ' +
                // Begin portfolio customizations
                'bg-[#0d1b25] md:bg-transparent',
            hidden: {
                on: 'hidden',
                off: '',
            },
        },
    },
};

export default flowbiteTheme;
